/* eslint-disable indent */
import api from '@/services/api'
import axios from 'axios'
import { sortBy, groupBy, capitalize } from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { getField, updateField } from 'vuex-map-fields'

const corLegenda = (chamado) => {
  let cor = null
  if (!chamado.dt_fechado) {
    cor = 'grey--text text--darken-4' // Preto
  }

  if (chamado.nr_nivel) {
    cor = 'red--text text--lighten-1' // Vermelho
  }

  if (
    (chamado.nr_nivel === '1' && chamado.ds_n1) ||
    (chamado.nr_nivel === '2' && chamado.ds_n2) ||
    (chamado.nr_nivel === '3' && chamado.ds_n3)) {
    cor = 'green--text' // Verde
  }

  if (!chamado.dt_invoice) {
    cor = 'grey--text' // Cinza
  }

  if (
    !chamado.dt_homolog ||
    !chamado.dt_develop ||
    !chamado.dt_backlog) {
    cor = 'light-blue--text text--darken-3' // Azul
  }

  if (!chamado.dt_cliente) {
    cor = 'primary--text' // Cor primária da aplicação
  } else {
    cor = 'amber--text text--accent-4' // Amarelo
  }
  chamado.ds_cor = cor

  return chamado
}

const replaceNota = (nota) => {
  const replaces = [
    '&nbsp;&nbsp;&nbsp;&nbsp;',
    '____',
    'background-color:white;margin-right:-0.40in;',
    'Clique no link abaixo para responder o chamado!',
    '&#61682;',
    'margin-right:-0.30in;',
    'margin-right: -0.10in;'
  ]

  replaces.forEach(r => {
    nota = nota.replace(r, '')
  })

  return nota
}

const estruturaVersoes = (response) => {
  const atualizacoes = []
  Object.entries(groupBy(
    sortBy(response.data, ['nr_versao']),
    'nr_versao'
  )).forEach(item => {
    atualizacoes.push({ nr_versao: item[0], atualizacoes: item[1] })
  })

  return atualizacoes.reverse()
}

export default {
  namespaced: true,
  state: {
    filtro: {
      dt_de: moment().subtract(60, 'days').format('DD/MM/YYYY'),
      dt_ate: moment().format('DD/MM/YYYY'),
      cd_fechado: 2, // Não
      cd_chamado: ''
    },
    chamado: {},
    cliente: {},
    funcionarios: [],
    notas: [],
    anexos: [],
    empresas: [],
    setores: [],
    atualizacoes: [],
    versao: 0,
    capitulos: [],
    solicitacoes: [],
    listaAnexos: [],
    listaChamados: [],
    listaVersoes: [],
    listaRetorno: [],
    banner: null,
    chatIniciado: false,
    chatOnline: false
  },
  getters: {
    getField
  },
  actions: {
    abrirListaRetornos ({ commit, rootState }) {
      api().post('se1/dotBoardCliente', { cd_usuario: rootState.login.user.cd_cliente }).then(response => {
        commit('SET_LISTA_RETORNO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    dotChamadoFinalizar ({ commit, state, rootState }, payload) {
      return api().post('se1/dotChamadoFinalizar', {
        cd_usuario: rootState.login.user.cd_cliente,
        cd_chamado: state.chamado.cd_chamado,
        nr_nota: payload.nr_nota,
        ds_nota: btoa(payload.ds_nota)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    visualizarChamado ({ commit, dispatch, rootState }, payload) {
      commit('SET_NOTAS', [])
      commit('SET_CHAMADO', {})
      commit('SET_LISTA_ANEXOS', [])
      api().post('se1/dotListaChamado', { cd_chamado: payload, cd_usuario: rootState.login.user.cd_cliente }).then(response => {
        commit('SET_CHAMADO', response.data[0])
        dispatch('listaNotas')
        dispatch('dotListaAnexo')
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    novoChamado ({ commit, state, rootState }) {
      return api().post('se1/dotTabelaChamado', {
        cd_usuario: rootState.login.user.cd_cliente,
        cd_operacao: 0,
        js_chamado: btoa(JSON.stringify({
          cd_chamado: 0,
          cd_empresa: state.chamado.cd_empresa,
          cd_capitulo: state.chamado.cd_capitulo,
          cd_cliente: rootState.login.user.cd_cliente,
          dt_chamado: moment().format('DD/MM/YYYY HH:mm'),
          ds_chamado: capitalize(state.chamado.ds_chamado.replace(/<[^>]+>/gms, '')),
          cd_funcionario: 0,
          bb_chamado: btoa(state.chamado.bb_chamado.replace(/<[^>]+>/gms, '')),
          bb_chamado_html: '',
          ds_dono: '',
          nr_tipo: 'W'
        }))
      }).then(response => {
        const id = response.data[0]?.Sucesso || response.data[0]?.cd_chamado
        if (id) {
          commit('SET_NOTAS', [])
          commit('SET_CHAMADO', {})
          commit('SET_LISTA_ANEXOS', [])
          commit('layout/SET_ALERTA', { mensagem: `Chamado gravado com sucesso n° ${id}`, tipo: 'sucesso' }, { root: true })
          return id
        } else if (response.data[0].Erro || response.data[0].Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
          return null
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Erro ao gravar !', type: 'warning' }, { root: true })
          return null
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listaNotas ({ commit, state, rootState }) {
      api().post('se1/dotListaNota', {
        cd_chamado: state.chamado.cd_chamado,
        cd_usuario: rootState.login.user.cd_cliente
      }).then(response => {
        const notas = response.data

        if (notas?.length) {
          notas[0].primeiro = true
          notas.forEach(nota => {
            const html = nota.bb_nota

            const dtNota = moment(nota.dt_nota, 'DD-MM-YYYY HH:mm')
            // Data de remoção do encode base64 duplo
            const dtEncode = moment('22-10-2019 16:00', 'DD-MM-YYYY HH:mm')

            if (dtNota.isSameOrBefore(dtEncode) ||
              (dtNota.isSameOrAfter(dtEncode) && nota.nr_tipo?.toLowerCase() !== 'w')) {
              try {
                nota.bb_nota = atob(atob(html))
              } catch (e) {
                nota.bb_nota = atob(html)
              }
            } else {
              nota.bb_nota = nota.nr_tipo?.toLowerCase() === 'w' ? atob(html) : nota.bb_nota
            }
            nota.bb_nota = replaceNota(nota.bb_nota)
          })
          commit('SET_NOTAS', notas)
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    dotListaAnexo ({ commit, state, rootState }) {
      api().post('se1/dotListaAnexo', { cd_chamado: state.chamado.cd_chamado }).then(response => {
        commit('SET_LISTA_ANEXOS', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    downloadAnexo ({ commit }, payload) {
      api().post('www/dotAnexoDownload',
        {
          cd_anexo: payload.cd_anexo
        },
        {
          responseType: 'blob',
          timeout: 10000
        }).then(response => {
          saveAs(response.data, payload.ds_anexo)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
    },

    novoAnexo ({ commit, state, dispatch }, index) {
      if (state.anexos.length) {
        if (state.anexos[index]) {
          const arquivo = state.anexos[index]
          const fReader = new FileReader()
          fReader.readAsDataURL(arquivo)
          fReader.onloadend = () => {
            api().post('se1/dotAnexoSalvar', {
              cd_chamado: state.chamado.cd_chamado,
              ds_arquivo: arquivo.name,
              bb_arquivo: fReader.result.replace(/^data:.+;base64,/, '')
            }).then(response => {
              dispatch('novoAnexo', (index + 1))
            })
          }
        } else {
          commit('SET_LISTA_ANEXOS', [])
          commit('SET_ANEXOS', [])
          dispatch('dotListaAnexo')
        }
      }
    },

    novaNota ({ commit, state, rootState }, payload) {
      return api().post('se1/dotTabelaNota', {
        cd_usuario: rootState.login.user.cd_cliente,
        cd_nota: '',
        cd_operacao: 0,
        js_nota: btoa(JSON.stringify({
          cd_nota: 0,
          bb_nota: btoa(payload.bb_nota_html.replace(/<[^>]+>/gms, '')),
          cd_chamado: state.chamado.cd_chamado,
          dt_nota: moment().format('DD/MM/YYYY HH:mm'),
          nr_tipo: 'W',
          ds_dono: rootState.login.user.ds_cliente,
        }))
      }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarChamados ({ commit, state, rootState }) {
      state.filtro.cd_usuario = rootState.login.user.cd_cliente
      api().post('se1/dotListaChamado', state.filtro).then(response => {
        const chamados = []
        if (response.data?.length) {
          response.data.forEach(chamado => {
            chamados.push(corLegenda(chamado))
          })
        }
        commit('SET_LISTA_CHAMADOS', chamados)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarVersoes ({ commit, dispatch, state }) {
      api().post('se1/dotFiltroVersao').then(response => {
        commit('SET_LISTA_VERSOES', response.data)
        if (state.versao === 0) {
          commit('SET_VERSAO', response.data[0].nr_versao)
          dispatch('filtrarVersao')
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    filtrarVersao ({ commit, state }) {
      api().post('se1/dotListaVersao', { cd_versao: state.versao }).then(response => {
        commit('SET_ULT_ATUALIZACOES', estruturaVersoes(response))
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    abrirUltimasAtualizacoes ({ commit, rootState }) {
      api().post('se1/dotBoardClinux', { cd_usuario: rootState.login.user.cd_cliente }).then(response => {
        commit('SET_ULT_ATUALIZACOES', estruturaVersoes(response))
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarEmpresas ({ commit, rootState }) {
      return api().post('se1/dotListaEmpresa', {
        cd_cliente: rootState.login.user.cd_cliente,
        cd_usuario: rootState.login.user.cd_cliente
      }).then(response => {
        commit('SET_EMPRESAS', response.data)
        return response.data
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarSetores ({ commit, rootState }) {
      api().post('se1/dotFiltroSetor', {
        cd_usuario: rootState.login.user.cd_cliente
      }).then(response => {
        commit('SET_SETORES', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarCapitulos ({ commit, dispatch }) {
      api().post('se1/dotFiltroCapitulo').then(response => {
        commit('SET_CAPITULOS', response.data)
        dispatch('listarSolicitacoes')
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarSolicitacoes ({ commit, dispatch }) {
      api().post('se1/dotFiltroSolicitacao').then(response => {
        commit('SET_SOLICITACOES', response.data)
        dispatch('listarEmpresas')
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    listarFuncionarios ({ commit, rootState }, payload) {
      api().post('se1/dotListaCliente', {
        cd_usuario: rootState.login.user.cd_cliente,
        cd_ativo: payload
      }).then(response => {
        commit('SET_FUNCIONARIOS', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    tabelaCliente ({ commit, rootState, state }, payload) {
      return api().post('se1/dotTabelaCliente', {
        cd_cliente: payload.cd_cliente,
        cd_operacao: payload.cd_operacao,
        cd_usuario: rootState.login.user.cd_cliente,
        js_cliente: btoa(JSON.stringify(payload.js_cliente || {}))
      }).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          let mensagem = response.data[0]?.Erro || response.data[0]?.Falha
          mensagem = mensagem.toLowerCase().includes('genesis_chamados_cd_cliente_fkey') ? 'Não é possível apagar este funcionário, pois existem chamados em seu nome.' : mensagem
          commit('layout/SET_ALERTA', { mensagem }, { root: true })
          return false
        } else {
          if (payload.cd_operacao === 3) {
            commit('SET_CLIENTE', response.data[0])
          } else if (response.data[0]?.Sucesso || response.data[0]?.cd_cliente) {
            commit('layout/SET_ALERTA', { mensagem: 'Dados atualizados com sucesso !', tipo: 'success' }, { root: true })
            /*
          } else if (payload.cd_operacao === 1 && response.data[0]?.Sucesso) {
            commit('layout/SET_ALERTA', { mensagem: 'Dados atualizados com sucesso !', tipo: 'success' }, { root: true })
          } else if (payload.cd_operacao === 0 && response.data[0]?.Sucesso) {
            commit('layout/SET_ALERTA', { mensagem: 'Dados atualizados com sucesso !', tipo: 'success' }, { root: true })
          } else if (payload.cd_operacao === 2 && response.data[0]?.Sucesso) {
            commit('layout/SET_ALERTA', { mensagem: 'Funcionário deletado com sucesso !', tipo: 'success' }, { root: true })
            // dispatch('listarFuncionarios', true)
            */
          } else {
            commit('layout/SET_ALERTA', { mensagem: 'Não foi possível identificar o registro, tente novamente !', tipo: 'warning' }, { root: true })
          }
          return true
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    validarChatOnline ({ commit }) {
      // Departamento 2 = Suporte RIS
      return axios.get('https://gtecnologia.livehelperchat.com/restapi/isonline').then(response => {
        commit('SET_CHAT_ONLINE', response?.data?.isonline || false)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    carregarBanner ({ commit }) {
      return api().post('se1/dotOtrsBanner')
        .catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
    },

    updatePassword ({ commit, rootState }, payload) {
      return api().post('se1/dotOtrsPasswordUpdate', {
        cd_cliente: rootState.login.user.cd_cliente,
        ds_old: payload.antiga,
        ds_new: payload.senha
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    recuperarSenha ({ commit }, payload) {
      return api().post('se1/dotOtrsPassword', { ds_email: payload }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  mutations: {
    updateField,
    SET_LISTA_CHAMADOS (state, payload) {
      state.listaChamados = payload
    },

    SET_ULT_ATUALIZACOES (state, payload) {
      state.atualizacoes = payload
    },

    SET_CHAMADO (state, payload) {
      state.chamado = payload
    },

    SET_LISTA_VERSOES (state, payload) {
      state.listaVersoes = payload
    },

    SET_VERSAO (state, payload) {
      state.versao = payload
    },

    SET_CLIENTE (state, payload) {
      state.cliente = payload
    },

    SET_NOTAS (state, payload) {
      state.notas = payload
    },

    SET_ANEXOS (state, payload) {
      state.anexos = payload
    },

    SET_CHAT_ONLINE (state, payload) {
      state.chatOnline = payload
    },

    SET_LISTA_ANEXOS (state, payload) {
      state.listaAnexos = payload
    },

    SET_LISTA_RETORNO (state, payload) {
      state.listaRetorno = payload
    },

    SET_EMPRESAS (state, payload) {
      state.empresas = payload
    },

    SET_SETORES (state, payload) {
      state.setores = payload
    },

    SET_CAPITULOS (state, payload) {
      state.capitulos = payload
    },

    SET_SOLICITACOES (state, payload) {
      state.solicitacoes = payload
    },

    SET_FUNCIONARIOS (state, payload) {
      state.funcionarios = payload
    },

    SET_CHAT (state, payload) {
      state.chatIniciado = payload
    }
  }
}
