import axios from 'axios'
import moment from 'moment'
import store from '@/store'
// import { myLocalStorage } from '@/mixins/localstore'

const LUMEN_API = 'https://lumen.clinux.com.br'

function urlLogin () {
  const url = window.location.pathname.split('/')
  return `/${url[1]}/${url[2]}/login`
}

function setParam (params) {
  params = params || {}
  params.token = store.getters['login/token'] || ''
  if (!params.token) {
    params.cd_funcionario = 1
  }
  const formData = new FormData()
  for (const key in params) {
    if ({}.toString.call(params[key]) !== '[object Function]' && params[key] !== undefined) {
      formData.append(key, params[key])
    }
  }
  return formData
}

function setData (config) {
  const params = config.data || {}
  const cliente = store.state.cgi.cliente.id
  params.token = store.getters['login/token'] || ''
  if (!params.token) {
    params.cd_funcionario = 1
  }
  const endpoint = `${store.state.cgi.cliente.url}/${config.url}`
  return { cliente, params, endpoint }
}

function redirectErrors (error) {
  console.error(error)
  switch (error.response.status) {
    case 401:
      error = new Error('Usuário ou sessão inválida !')
      store.dispatch('login/logout').then(() => {
        window.open(urlLogin(), '_self')
      })
      break
    default:
      return Promise.reject(error)
  }
}

function getToken (url) {
  let data = { token: store.getters['login/token'] }
  if ((url.includes('https') || url.includes('localhost'))) {
    axiosToken.defaults.baseURL = store.state.cgi.cliente.url
    data = setParam(data)
  } else {
    axiosToken.defaults.baseURL = LUMEN_API
    data = setData({ data, url })
  }
  axiosToken.post('se1/getToken', data).then(response => {
    if (response.data[0]?.token) {
      store.commit('login/auth_token', response.data[0].token)
      store.commit('login/auth_valid', moment().format())
    } else {
      store.dispatch('login/logout')
      window.open(urlLogin(), '_self')
    }
  }).catch(e => {
    return redirectErrors(e)
  })
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000
}

// Intânca Axios
const axiosInstance = axios.create(defaultOptions)
const axiosToken = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(config => {
  const url = store.state.cgi.cliente.url.toLowerCase()
  if ((url.includes('https') || url.includes('localhost'))) {
    config.baseURL = axiosInstance.defaults.baseURL = store.state.cgi.cliente.url
    config.data = setParam(config.data)
  } else {
    config.baseURL = axiosInstance.defaults.baseURL = LUMEN_API
    // Redirecionamento via Lumen não possibilita multpart/form-data
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      config.headers['Content-Type'] = 'application/json'
    }
    config.data = setData(config)
  }
  const loading = store.getters['layout/loading']
  const valid = moment(store.state.login.valid)
  // const token = store.getters['login/token']
  if (!loading && valid && moment().diff(valid, 'minutes') > 30) {
    getToken(url)
  }
  if (store.getters['login/token'])
    config.headers['Authorization'] = `Bearer ${store.getters['login/token']}`
  store.commit('layout/START_LOADING')
  return config
}, error => {
  store.commit('layout/FINISH_LOADING')
  console.error(error)
  // return Promise.reject(error)
})

axiosInstance.interceptors.response.use(response => {
  store.commit('layout/FINISH_LOADING')
  return response
}, error => {
  store.commit('layout/FINISH_LOADING')
  console.error(error)
  return redirectErrors(error)
})

export default () => {
  return axiosInstance
}
